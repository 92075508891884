import { GenerateStringManipulation } from '@shared/interfaces/string-manipulation.interface'

/**
 * Make the first letter of a string uppercase
 * @param word
 */
export default function capitalizeString(word: string): string {
  const _word = word.toLocaleLowerCase()
  return _word.charAt(0).toUpperCase() + _word.slice(1)
}

/**
 * Check if a string is empty
 * @param value
 */
export function isEmptyString(value: string | null | undefined): boolean {
  return value !== undefined && value !== null && value.length === 0
}

/**
 * Truncate text on ah specific char quantity and include ellipsis text at the end.
 * @param text
 * @param truncateAt
 */
export function truncateTextWithEllipsis(text: string, truncateAt: number) {
  if (text.length > truncateAt) {
    // Find the index of the last space within the maxLength
    const lastSpaceIndex = text.lastIndexOf(' ', truncateAt)

    // If a space is found, truncate at that position; otherwise, truncate at maxLength
    const truncatedText =
      lastSpaceIndex !== -1 ? text.substring(0, lastSpaceIndex) : text.substring(0, truncateAt)

    // Add "..." after the last complete word
    return truncatedText + '...'
  }
  return text
}

/*
 * Generate dynamic texts to allow me to store dynamic texts as constants variables
 * @param textValues
 * @param stringToUse
 */
export const generateDynamicTexts = (
  textValues: GenerateStringManipulation,
  stringToUse: string,
) => {
  let generatedString = stringToUse
  Object.keys(textValues).map((name) => {
    const value = textValues[name]
    generatedString = generatedString.replaceAll(`{${name}}`, value)
  })
  return generatedString
}

/**
 * Remove HTML from string
 */
export const removeHtmlTags = (content: string) => {
  return content.replace(/<[^<>]*>/g, '')
}

export const pluralize = (qty: number) => (qty <= 0 ? '' : 's')

export const toCamelCase = (toCamelString: string, splitChar = '-') =>
  toCamelString
    .split(splitChar)
    .map((word, index) => capitalizeString(word))
    .join('')

/**
 * Remove all whitespace from a string
 * @param text
 */
export const removeWhitespace = (text: string) => text.replace(/\s/g, '')

/**
 * Remove Hyphens
 */
export const removeHyphens = (text: string) => text.replace(/-/g, ' ')

export const replaceSpecialCharsWithHyphen = (str: string) =>
  str.replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-')

export const replaceSpecialCharsWith = (str: string, replaceBy: string) =>
  str.replace(/[^a-zA-Z0-9 ]/g, replaceBy).replace(/\s+/g, replaceBy)

export const snakeToNormal = (snake: string): string => {
  if (!snake) return ''
  return snake.split('_').join(' ')
}

/**
 * Join Strings
 * @param str
 * @param concatWith
 */
export const concatStrings = (
  str: (string | null | undefined)[],
  concatWith: string = ' ',
): string => {
  return str.filter((s): s is string => s != null).join(concatWith)
}
