import {
  ContentVideoReviewsRequestsInterface,
  SubmitVideoReviewResponseInterface,
  UserVideoReviewsStateInterface,
  VideoReviewInterface,
} from '@shared/interfaces/content/video-review.interface'

export const fetchUserVideoReviewsReducer = (state: UserVideoReviewsStateInterface) => {
  state.fetchStatus = 'loading'
}

export const fetchUserVideoReviewsSuccessReducer = (
  state: UserVideoReviewsStateInterface,
  {
    payload: userVideoReviews,
  }: {
    payload: ContentVideoReviewsRequestsInterface
  },
) => {
  state.fetchStatus = 'succeeded'
  state.videoReviewsRequest = [...state.videoReviewsRequest, userVideoReviews]
}

export const fetchUserVideoReviewsFailureReducer = (state: UserVideoReviewsStateInterface) => {
  state.fetchStatus = 'failed'
}

// Submit video review
export const submitVideoReviewReducer = (state: UserVideoReviewsStateInterface) => {
  state.fetchStatus = 'loading'
}

export const submitVideoReviewSuccessReducer = (
  state: UserVideoReviewsStateInterface,
  {
    payload: payload,
  }: {
    payload: SubmitVideoReviewResponseInterface
  },
) => {
  const contentIndex = state.videoReviewsRequest.findIndex(
    (content) => content.contentId === payload.contentId,
  )

  if (contentIndex !== -1) {
    const existingRequests = state.videoReviewsRequest[contentIndex].requests
    const requestIndex = existingRequests.findIndex(
      (request) => request.videoReviewRequestId === payload.videoReviewRequest.videoReviewRequestId,
    )

    if (requestIndex !== -1) {
      existingRequests[requestIndex] = payload.videoReviewRequest
    } else {
      existingRequests.push(payload.videoReviewRequest)
    }

    // Update the state directly with the modified requests
    state.videoReviewsRequest[contentIndex] = {
      ...state.videoReviewsRequest[contentIndex],
      requests: existingRequests,
    }
  }

  // Set the fetch status
  state.fetchStatus = 'succeeded'
}

export const submitVideoReviewFailureReducer = (state: UserVideoReviewsStateInterface) => {
  state.fetchStatus = 'failed'
}
